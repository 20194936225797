<template>

<app-wrapper :content="true" class="paginate">

	<div class="paginate-button" :class="{'is-active': current === page}" v-for="page in range" :key="page" v-on:click="$emit('click', page)">{{ page }}</div>

</app-wrapper>

</template>

<script>

export default {

	props: ['loading', 'current', 'total'],

	computed: {

		range: function() {

			var pages = Math.ceil(this.total / 14)
			var range = []

			for (var i=1; i<=pages; i++) {

				range.push(i)

			}
			
			return range

		}

	}

}

</script>

<style scoped>

.paginate {
	padding: 0px 0px 64px 0px;
	display: flex;
	justify-content: flex-end;
}

.is-tablet .paginate {
	padding-bottom: 48px;
}

.is-mobile .paginate {
	padding-bottom: 32px;
	justify-content: center;
}

.paginate-button {
	height: 48px;
	min-width: 48px;
	border-radius: 50%;
	padding: 0px 10px;
	font-size: 14px;
	margin-left: 10px;
	font-weight: 500;
	color: #45464B;
	user-select: none;
	text-align: center;
	line-height: 48px;
	background-color: #EDEFFA;
	cursor: pointer;
}

.paginate-button.is-active {
	color: #fff;
	background-color: #485CC7;
	pointer-events: none;
}

.is-tablet .paginate-button,
.is-mobile .paginate-button {
	height: 40px;
	line-height: 40px;
	font-size: 14px;
}

.is-desktop .paginate-button:hover {
	background-color: #112a45;
	color: #fff;
}

</style>
